<template>
  <div>
    <section class="section">
      <div class="title">
        <span>
          <span
            class="news"
            @click="tabShow = true"
            :class="{ butActive: tabShow }"
            >新闻类</span
          >
          <span class="vertical"></span>
          <span
            class="activity"
            :class="{ butActive: !tabShow }"
            @click="tabShow = false"
            >活动类</span
          >
        </span>
      </div>
      <div class="section-center">
        <div class="tab">
          <div class="newscalss" v-show="tabShow">
            <div class="itemtop">
              <div class="imgbox">
                <img
                  src="../../assets/image/enterpriseNews/list/news-img.png"
                  alt=""
                />
              </div>
              <div class="text">
                <div class="right">
                  <p class="text-title">
                    有为信通助事业单位各类会议、活动顺利进行！
                  </p>
                  <div class="content">
                    <span
                      >有为信通专注于客户数据业务创新，依托云计算、大数据技术为众多政府、教育、电力、能源、企业等行业提供创新性的产品和解决方案。有为信通的业务方向涉及智慧城市、智慧电网、IDC智能化云运维，产品涵盖智能化音视频信息通信，大数据业务可视化、信息数据交换共享，IDC系统集成及智能化云运维服务、智慧园区创新应用管理平台等领域。···</span
                    >
                  </div>
                  <p class="detailsbtn">
                    <span class="imgs" @click="detailsEvent('Thankyouletter')"
                      >查看详情</span
                    >
                    <!-- <img
                      src="../../assets/image/enterpriseNews/list/icon3.png"
                      alt=""
                      @click="detailsEvent('Thankyouletter')"
                    /> -->
                  </p>
                  <p class="date">2020年6月19</p>
                  <img
                    class="top"
                    src="../../assets/image/enterpriseNews/list/news-top.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
           <div class="itemtop item">
              <div class="imgbox">
                <img
                style="width:307px;height:191px;"
                  src="../../assets/image/enterpriseNews/list/news-img10.jpg"
                  alt=""
                />
              </div>
              <div class="text">
                <div class="right">
                  <p class="text-title">"助力唐山乡村振兴帮扶行动"正式启动——祝贺祥业科技集团与唐山市政府签订合作协议！</p>
                  <div class="content">
                    <span
                      >2023年1月7日，北京唐山企业商会、华夏董氏实业集团“助力唐山乡村振兴帮扶行动”启动仪式在唐山举行，主办方为华夏董氏实业集团。会场气氛高涨，热情洋溢，全球唐商联盟、北京唐山企业商会，央企、国家慈善机构等，以及数十家在京企业参加。全国政协常委、唐山市政府等领导给予高度重视，出席并讲话。祥业科技集团受邀参加。</span
                    >
                  </div>
                  <p class="detailsbtn">
                    <!-- <img
                      src="../../assets/image/enterpriseNews/list/icon3.png"
                      alt=""
                      @click="detailsEvent('Housewarming')"
                    /> -->
                    <span class="imgs" @click="detailsEvent('tangshan')"
                      >查看详情</span
                    >
                    <span>2023年1月7日</span>
                  </p>
                </div>
                <!-- <p class="date">2021年5月25</p> -->
              </div>
            </div>
           <div class="itemtop item">
              <div class="imgbox">
                <img
                style="width:307px;height:191px;"
                  src="../../assets/image/enterpriseNews/tangshan/ts2.jpg"
                  alt=""
                />
              </div>
              <div class="text">
                <div class="right">
                  <p class="text-title">热烈欢迎唐山市丰南区领导一行到访祥业集团！</p>
                  <div class="content">
                    <span
                      >2023年2月3日，唐山市丰南区领导一行到访祥业集团，就集团投资建设公共计算产业示范基地项目进行考察洽谈。集团主要领导接待并与会洽谈。</span
                    >
                  </div>
                  <p class="detailsbtn">
                    <!-- <img
                      src="../../assets/image/enterpriseNews/list/icon3.png"
                      alt=""
                      @click="detailsEvent('Housewarming')"
                    /> -->
                    <span class="imgs" @click="detailsEvent('tsvisit')"
                      >查看详情</span
                    >
                    <span>2023年2月3日</span>
                  </p>
                </div>
                <!-- <p class="date">2021年5月25</p> -->
              </div>
            </div>
            <div class="itemtop item">
              <div class="imgbox">
                <img
                  src="../../assets/image/enterpriseNews/list/news-img1.png"
                  alt=""
                />
              </div>
              <div class="text">
                <div class="right">
                  <p class="text-title">有为信通乔迁之喜</p>
                  <div class="content">
                    <span
                      >2021年05月25日，有为信通乔迁之喜暨华为智能协作体验中心揭幕仪式在公司新址隆重举行，华为领导和公司领导共同为公司乔迁之喜剪彩，并宣布公司正式入驻新办公区（北京市丰台科技园盈坤世纪D座8层），华为领导还为公司的“华为北京机器视觉好望学堂能力中心”举行了授牌仪式，现场掌声热烈。</span
                    >
                  </div>
                  <p class="detailsbtn">
                    <!-- <img
                      src="../../assets/image/enterpriseNews/list/icon3.png"
                      alt=""
                      @click="detailsEvent('Housewarming')"
                    /> -->
                    <span class="imgs" @click="detailsEvent('Housewarming')"
                      >查看详情</span
                    >
                    <span>2021年5月25</span>
                  </p>
                </div>
                <!-- <p class="date">2021年5月25</p> -->
              </div>
            </div>

            <div class="itemtop item">
              <div class="imgbox">
                <img
                  src="../../assets/image/enterpriseNews/list/news-img2.png"
                  alt=""
                />
              </div>
              <div class="text">
                <div class="right">
                  <p class="text-title">2021年有为信通团建暨客户答谢会</p>
                  <div class="content">
                    <span
                      >2021年4月17-18日，为表达对客户朋友对公司业务的大力支持，和提高员工的团队凝聚力，有为信通在北京天毓山庄特举办“行稳致远，进而有为——2021年有为信通团建暨客户答谢会”，到会者近200人，大家同聚一堂，共享盛宴！
                    </span>
                  </div>
                  <p class="detailsbtn">
                    <!-- <img
                      src="../../assets/image/enterpriseNews/list/icon3.png"
                      alt=""
                      @click="detailsEvent('Thankyoumeeting')"
                    /> -->
                    <span class="imgs" @click="detailsEvent('Thankyoumeeting')"
                      >查看详情</span
                    >
                    <span>2020年4月17</span>
                  </p>
                </div>
              </div>
            </div>

            <div class="itemtop item">
              <div class="imgbox">
                <img
                  src="../../assets/image/enterpriseNews/list/news-img3.png"
                  alt=""
                />
              </div>
              <div class="text">
                <div class="right">
                  <p class="text-title">有为信通荣获丰台科技园支持资金！</p>
                  <div class="content">
                    <span
                      >2021年4月17-携手共进，风雨同舟——北京有为信通科技发展有限公司获得丰台科技园“创新十二条”2019年度（第二批）支持资金5万元。感谢中关村科技园区丰台区管委会的支持，大家的认可是我们前进的动力，2021新的起点，我们将勇攀高峰，再创佳绩！
                    </span>
                  </div>
                  <p class="detailsbtn">
                    <!-- <img
                      src="../../assets/image/enterpriseNews/list/icon3.png"
                      alt=""
                      @click="detailsEvent('reward')"
                    /> -->
                    <span class="imgs" @click="detailsEvent('reward')"
                      >查看详情</span
                    >
                    <span>2020年1月06</span>
                  </p>
                </div>
              </div>
            </div>

            <div class="itemtop item">
              <div class="imgbox">
                <img
                  src="../../assets/image/enterpriseNews/list/news-img4.png"
                  alt=""
                />
              </div>
              <div class="text">
                <div class="right">
                  <p class="text-title">守望初心，行稳致远</p>
                  <div class="content">
                    <span
                      >2021年4月17-2020年，受疫情影响，中华民族经历了巨大考验，企业也不能例外......
                      有为信通自年初复工以来，兢兢业业做好疫情防控工作，孜孜以求把优质的产品技术服务带给客户。同时以优秀的成绩给2020画上了一个完美的句号
                      ···
                    </span>
                  </div>
                  <p class="detailsbtn">
                    <!-- <img
                      src="../../assets/image/enterpriseNews/list/icon3.png"
                      alt=""
                      @click="detailsEvent('slogan')"
                    /> -->
                    <span class="imgs" @click="detailsEvent('slogan')"
                      >查看详情</span
                    >
                    <span>2020年2月05</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="activitycalss" v-show="!tabShow">
            <div class="itemtop">
              <div class="imgbox">
                <img
                  src="../../assets/image/enterpriseNews/list/activity-img.png"
                  alt=""
                />
              </div>
              <div class="text">
                <div class="right">
                  <p class="text-title">
                    有为信通助事业单位各类会议、活动顺利进行！
                  </p>
                  <div class="content">
                    <span
                      >有为信通专注于客户数据业务创新，依托云计算、大数据技术为众多政府、教育、电力、能源、企业等行业提供创新性的产品和解决方案。有为信通的业务方向涉及智慧城市、智慧电网、IDC智能化云运维，产品涵盖智能化音视频信息通信，大数据业务可视化、信息数据交换共享，IDC系统集成及智能化云运维服务、智慧园区创新应用管理平台等领域。···</span
                    >
                  </div>
                  <p class="detailsbtn">
                    <!-- <img
                      src="../../assets/image/enterpriseNews/list/icon3.png"
                      alt=""
                      @click="detailsEvent('lecture')"
                    /> -->
                    <span class="imgs" @click="detailsEvent('lecture')"
                      >查看详情</span
                    >
                  </p>
                  <p class="date">2020年6月19</p>
                  <img
                    class="top"
                    src="../../assets/image/enterpriseNews/list/news-top.png"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div class="itemtop item">
              <div class="imgbox">
                <img
                  src="../../assets/image/enterpriseNews/list/activity-img1.png"
                  alt=""
                />
              </div>
              <div class="text">
                <div class="right">
                  <p class="text-title">
                    有为信通护航“云游非遗·影像展”会议活动直播顺利进行！
                  </p>
                  <div class="content">
                    <span
                      >6月11日，2021年文化和自然遗产日“云游非遗·影像展”“非遗购物节”启动仪式在京举行。文化和旅游部党组书记、部长胡和平出席活动并讲话，部党组成员王晓峰出席活动。此次活动，主办方选用华为视频会议设备全程进行视频直播，有为信通为会议活动直播，提供相关设备和技术支持，保障了直播顺利进行，画面清晰、流畅，操作便捷、智能，专业度可圈可点！</span
                    >
                  </div>
                  <p class="detailsbtn">
                    <!-- <img
                      src="../../assets/image/enterpriseNews/list/icon3.png"
                      alt=""
                      @click="detailsEvent('Imageexhibition')"
                    /> -->
                    <span class="imgs" @click="detailsEvent('Imageexhibition')"
                      >查看详情</span
                    >
                    <span>2021年5月25</span>
                  </p>
                </div>
                <!-- <p class="date">2021年5月25</p> -->
              </div>
            </div>

            <div class="itemtop item">
              <div class="imgbox">
                <img
                  src="../../assets/image/enterpriseNews/list/news-img2.png"
                  alt=""
                />
              </div>
              <div class="text">
                <div class="right">
                  <p class="text-title">2021年有为信通团建暨客户答谢会</p>
                  <div class="content">
                    <span
                      >2021年4月17-18日，为表达对客户朋友对公司业务的大力支持，和提高员工的团队凝聚力，有为信通在北京天毓山庄特举办“行稳致远，进而有为——2021年有为信通团建暨客户答谢会”，到会者近200人，大家同聚一堂，共享盛宴！
                    </span>
                  </div>
                  <p class="detailsbtn">
                    <!-- <img
                      src="../../assets/image/enterpriseNews/list/icon3.png"
                      alt=""
                      @click="detailsEvent('Thankyoumeeting')"
                    /> -->
                    <span class="imgs" @click="detailsEvent('Thankyoumeeting')"
                      >查看详情</span
                    >
                    <span>2020年4月17</span>
                  </p>
                </div>
              </div>
            </div>

            <div class="itemtop item">
              <div class="imgbox">
                <img
                  src="../../assets/image/enterpriseNews/list/news-img4.png"
                  alt=""
                />
              </div>
              <div class="text">
                <div class="right">
                  <p class="text-title">守望初心，行稳致远</p>
                  <div class="content">
                    <span
                      >2021年4月17-2020年，受疫情影响，中华民族经历了巨大考验，企业也不能例外......
                      有为信通自年初复工以来，兢兢业业做好疫情防控工作，孜孜以求把优质的产品技术服务带给客户。同时以优秀的成绩给2020画上了一个完美的句号
                      ···
                    </span>
                  </div>
                  <p class="detailsbtn">
                    <!-- <img
                      src="../../assets/image/enterpriseNews/list/icon3.png"
                      alt=""
                      
                    /> -->
                    <span class="imgs" @click="detailsEvent('slogan')"
                      >查看详情</span
                    >
                    <span>2020年2月05</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabShow: true,
    };
  },
  created(){
    if(this.$route.params.type){
      this.tabShow=false;
    }
  },
  methods: {
    detailsEvent(r) {
      this.$router.push("/" + r);
    },
  },
  
};
</script>

<style lang="scss" scoped>
.title {
  padding-top: 50px;
  span {
    span {
      display: inline-block;
      font-size: 30px;
      color: #999;
      cursor: pointer;
    }
    .vertical {
      width: 1px;
      height: 30px;
      background: #999;
      margin: 0 30px;
      vertical-align: sub;
    }
  }
  .butActive {
    color: #6c35e4;
  }
}
.section {
  background-image: url("../../assets/image/enterpriseNews/list/newsbg.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  .section-center {
    font-size: 18px;
    color: #000;
    max-width: 1466px;
    margin: 0 auto;
    .tab {
      padding-top: 40px;
      .itemtop {
        height: 320px;
        margin-bottom: 32px;
        .imgbox {
          float: left;
          margin-top: 28px;
          z-index: 1;
          overflow: hidden;
          position: relative;
        }
        .text {
          z-index: 0;
          width: 1156px;

          background: #f4f4f4;
          border-radius: 2px;
          margin-left: 310px;
          position: relative;

          .top {
            position: absolute;
            right: 0;
            top: 0;
          }
          .right {
            text-align: left;
            padding: 60px 40px 20px 145px;
            .text-title {
              height: 24px;
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #000000;
              margin-bottom: 22px;
            }
            .content {
              height: 80px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              line-height: 32px;
              margin-bottom: 36px;
            }
            .detailsbtn {
              margin-bottom: 24px;
              cursor: pointer;
              .imgs {
                width: 93px;
                height: 31px;
                display: block;
                line-height: 31px;
                text-align: center;
                color: #999;
                border: 1px solid;
                // background: url(../../assets/image/enterpriseNews/list/icon3.png);
              }
            }
            .date {
              text-align: right;
            }
          }
        }
      }
      .item {
        height: 222px;
        margin-bottom: 32px;
        .imgbox {
          margin-top: 20px;
        }
        .text {
          width: 1316px;
          margin-left: 150px;

          .right {
            padding: 36px 41px 30px 195px;
            .text-title {
              margin-bottom: 16px;
            }
            .content {
              height: 64px;
              margin-bottom: 21px;
              line-height: 24px;
            }
            .detailsbtn {
              margin-bottom: 0;
              display: flex;
              justify-content: space-between;
              span {
                color: #999;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

// hover事件
.itemtop:hover .imgbox img {
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: 0.35s;
  transition: 0.35s;
}
.itemtop:hover .text .right .detailsbtn .imgs {
  color: #6c35e4 !important;
}
.itemtop:hover .text .right .text-title {
  color: #6c35e4 !important;
}
.itemtop .text .right .detailsbtn .imgs:hover {
  color: white !important;
  background-color: #6c35e4 !important;
}
</style>